import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import publishersForm from "../forms/publishers.json";
import publishersUI from "../forms/publishersUi.json";
import React from "react";
import { AlertType, ContactsEnum, Publisher, PublisherBase } from "../models";
import { createPublisherDataByForm } from "../utils";
import {
  addPublisher,
  getAllPublishers,
  updatePublisher,
} from "../api/publishers";
import {
  setPublishers,
  updatePublishersSelectors,
} from "../redux/slices/publisher/publisher";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/spinner/spinner";
import { RootState } from "../redux/store";
import Alert from "../components/alert/alert";

const PublisherEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const selectorPublishers = useSelector(
    (state: RootState) => state.publisher.publishers
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );
  const [formDataValue, setFormData] = React.useState<any>(undefined);
  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const publisherToUpdate = React.useMemo(
    () => selectorPublishers.find((publisher) => publisher.id === params.id),
    [params.id, selectorPublishers]
  );

  const getDefaultFormData = React.useCallback(() => {
    try {
      if (publisherToUpdate) {
        const contacts: { contactType: ContactsEnum; contact: string }[] = [];
        if (publisherToUpdate.telefono) {
          contacts.push({
            contactType: ContactsEnum.Phone,
            contact: publisherToUpdate.telefono,
          });
        }
        if (publisherToUpdate.email) {
          contacts.push({
            contactType: ContactsEnum.Email,
            contact: publisherToUpdate.email,
          });
        }
        if (publisherToUpdate.fax) {
          contacts.push({
            contactType: ContactsEnum.Fax,
            contact: publisherToUpdate.fax,
          });
        }
        if (publisherToUpdate.website) {
          contacts.push({
            contactType: ContactsEnum.WebSite,
            contact: publisherToUpdate.website,
          });
        }
        return {
          code: publisherToUpdate.id,
          nominative: publisherToUpdate.nominativo,
          city: publisherToUpdate.citta ?? undefined,
          cap: publisherToUpdate.cap
            ? parseInt(publisherToUpdate.cap)
            : undefined,
          address: publisherToUpdate.indirizzo,
          contacts: contacts,
        };
      }
    } catch (err) {
      console.log("err getDefaultFormData publisher", err);
    }
  }, [publisherToUpdate]);

  const handleAddPublisher = async (publisherBase: Publisher) => {
    if (publisherBase) {
      try {
        const publisherToAdd = createPublisherDataByForm(
          publisherBase
        ) as PublisherBase;
        setLoading(true);
        const res = await addPublisher(publisherToAdd);
        if (res.data && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Editore non aggiunto`);
        }
        setLoading(false);
        navigate("/editori");
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const handleEditPublisher = async (publisherToUpdate: Publisher) => {
    if (publisherToUpdate) {
      const publisherDataToUpdate = createPublisherDataByForm(
        publisherToUpdate,
        true
      ) as Publisher;
      try {
        setLoading(true);
        const res = await updatePublisher(publisherDataToUpdate);
        console.log("publisher Updated", res);
        if (res.data && res.status === 200) {
          setMessage("success");
          dispatch(updatePublishersSelectors(res.data));
        } else {
          setMessage(`Editore non modificato`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const getPublishersFromDb = React.useCallback(async () => {
    const publishersOnDb = await getAllPublishers();
    dispatch(setPublishers(publishersOnDb.data));
  }, [dispatch]);

  React.useEffect(() => {
    if (publisherToUpdate) {
      const defaultForm = getDefaultFormData();
      if (defaultForm) {
        setFormData(defaultForm);
      }
    } else {
      getPublishersFromDb();
    }
  }, [getDefaultFormData, publisherToUpdate, params.id, getPublishersFromDb]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica editore" : "Inserisci nuovo editore"}</h3>
        <Link to="/editori" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={publishersForm}
        ui={publishersUI}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={params.id ? handleEditPublisher : handleAddPublisher}
        formData={formDataValue}
      />
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={params.id ? "Editore modificato" : "Editore aggiunto"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default PublisherEditPage;
