import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Providers } from '../../../models'

interface ProvidersState {
    currentProviders: Providers | undefined,
    providers: Providers[],
}


const initialState: ProvidersState = {
    currentProviders: undefined,
    providers: [],
}

export const providersSlice = createSlice({
    name: 'providersSlice',
    initialState,
    reducers: {

        updateProviders: (state, action: PayloadAction<Providers>) => {
            state.currentProviders = action.payload
        },

        setProviders: (state, action: PayloadAction<Providers[]>) => {
            state.providers = action.payload
        },

        updateProvidersSelectors: (state, action: PayloadAction<Providers>) => {
            state.providers = state.providers.map(el => {
                if (el.id === action.payload.id) {
                    return action.payload
                }
                else return el
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateProviders, setProviders, updateProvidersSelectors } = providersSlice.actions

export default providersSlice.reducer