import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../routes/index';
import GlobalStyle from '../views/style';
import { Layout } from '../components/layout/style';
import SideMenu from '../components/sideMenu/sideMenu';

const Index = () => {
    const location = useLocation();
    console.log(location.pathname);

    return (
        <>
            <GlobalStyle />
            <Layout className={location.pathname === '/' ? '' : 'withMenu'}>
                {location.pathname === '/' ? (
                    <AppRoutes />
                ) : (
                    <>
                        <SideMenu />
                        <div>
                            <AppRoutes />
                        </div>
                    </>
                )}
            </Layout>
        </>
    );
};

export default Index;
