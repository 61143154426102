import styled from 'styled-components';

export const SectionLogin = styled.section`
	background: var(--green);
	img {
		width: 150px;
		margin-top: 50px;
	}
	.card {
		border-radius: 10px;
		box-shadow: 2px 2px 4px var(--blue);
	}
`;

