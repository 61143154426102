import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import articlesForm from "../forms/articles.json";
import { AlertType, ArticlesBase } from "../models";
import React from "react";
import { addArticle } from "../api/articles";
import Spinner from "../components/spinner/spinner";
import Alert from "../components/alert/alert";
import articlesUi from "../forms/articlesUi.json";

const ArticlesEditPage = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );

  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const handleEditArticle = () => {};

  const handleAddArticle = async (articleBase: ArticlesBase) => {
    if (articleBase) {
      try {
        setLoading(true);
        const res = await addArticle(articleBase);
        if (res.data && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Articolo non aggiunto`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica articolo" : "Inserisci nuovo articolo"}</h3>
        <Link to="/sedi" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={articlesForm}
        ui={articlesUi}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={params.id ? handleEditArticle : handleAddArticle}
      />
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={"Articolo aggiunto"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default ArticlesEditPage;
