import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { PurchaseHistoryStyle } from './style';

const PurchaseHistory = () => {
    const data = [
        {
            name: '01/2023',
            sede1: 4000,
            sede2: 2400,
            amt: 2400,
        },
        {
            name: '02/2023',
            sede1: 3000,
            sede2: 1398,
            amt: 2210,
        },
        {
            name: '03/2023',
            sede1: 2000,
            sede2: 9800,
            amt: 2290,
        },
        {
            name: '04/2023',
            sede1: 2780,
            sede2: 3908,
            amt: 2000,
        },
        {
            name: '05/2023',
            sede1: 1890,
            sede2: 4800,
            amt: 2181,
        },
        {
            name: '06/2023',
            sede1: 2390,
            sede2: 3800,
            amt: 2500,
        },
        {
            name: '07/2023',
            sede1: 3490,
            sede2: 4300,
            amt: 2100,
        },
    ];

    return (
        <div className="container-fluid px-4 py-5">
            <h2 className="pb-2 border-bottom">Storico acquisti</h2>
            <PurchaseHistoryStyle>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="sede1"
                            stroke="var(--green)"
                            activeDot={{ r: 8 }}
                        />
                        <Line
                            type="monotone"
                            dataKey="sede2"
                            stroke="var(--blue)"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </PurchaseHistoryStyle>
        </div>
    );
};

export default PurchaseHistory;
