import styled from 'styled-components';

export const FormXStyle = styled.div`
    form {
        margin-top: 20px;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        border: 1px solid var(--blue);
        box-shadow: 2px 2px 4px var(--blue);
        margin-bottom: 20px;
        padding-bottom: 20px;
        & > .form-group > fieldset {
            border: none;
        }
    }
    .subSelect .field-object fieldset .field-string:first-child,
    .select {
        position: relative;
        &:after {
            content: "▼";
            position: absolute;
            top: 30px;
            right: 10px;
            color: #0061a8;
            pointer-events: none;
        }
    }
    fieldset {
        margin: 0;
        padding: 0;
    }
    fieldset > legend#root__title {
        background: var(--blue);
        color: #fff;
        padding: 0 12px;
        margin: 0;
        & + p.field-description {
            font-size: 13px;
            color: #959595;
            padding: 0 12px;
        }
    }
    fieldset div.form-group {
        margin: 0 10px 10px 10px;
        .form-control:focus {
            border-color: var(--blue);
            box-shadow: 0 0 0 0.25rem var(--blue);
        }
        input::-webkit-inner-spin-button {
            color: #fff;
            filter: sepia(1);
        }
        input::-webkit-calendar-picker-indicator {
            filter: invert(21%) sepia(91%) saturate(1842%) hue-rotate(187deg) brightness(99%) contrast(102%);
            cursor: pointer;
        }
        input::-webkit-datetime-edit-day-field:focus,
        input::-webkit-datetime-edit-month-field:focus,
        input::-webkit-datetime-edit-year-field:focus,
        input::selection {
            background: var(--blue);
            color: #000;
            outline: none;
        }
    }
    .array-item {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        & > .col-xs-9 {
            width: 100%;
        }
        & > div > .form-group {
            margin: 0 0 4px 0;
        }
        fieldset {
            & > div.form-group {
                width: 46%;
                display: inline-block;
                margin-bottom: 4px;
            }
        }
    }
    button.btn-info {
        margin-left: 12px;
        will-change: transform;
    }
    .array-item-toolbox .btn-group {
        margin-top: 24px;
        button {
            font-size: 12px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 38px;
            &.array-item-move-up {
                background: var(--blue);
            }
            &.array-item-move-down {
                background: var(--blue);
				border-left: 1px solid var(--accent);
				& + .array-item-remove {
					border-left: 1px solid var(--accent);
				}
            }
        }
    }
    .checkbox {
        label {
            cursor: pointer;
			input {
				margin-right: 5px;
				appearance: none;
				background-color: #fff;
				font: inherit;
				color: currentColor;
				width: 1.15em;
				height: 1.15em;
				border: 0.15em solid var(--blue);
				border-radius: 0.15em;
				transform: translateY(-0.075em);
				cursor: pointer;
				display: inline-grid;
				place-content: center;
				&:before {
					content: "";
					width: 0.65em;
					height: 0.65em;
					transform: scale(0);
					transition: 120ms transform ease-in-out;
					box-shadow: inset 1em 1em var(--green);
				}
				&:checked:before {
					transform: scale(1);
				}
			}
        }
    }
	.checkbox-list {
		font-size: 1rem;
	}
	.array-item-list + .row p {
		text-align: right;
	}
    textarea {
        resize: none;
    }
`;
