import moment from "moment";
import { ContactsEnum, InfoClient, InfoClientBase, LocationBase, LocationModel, Providers, ProvidersBase, Publisher, PublisherBase, UserModel, UserModelBase, UserType } from "../models";

export const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const createClientDataByForm = (clientDataForm: any, full: boolean = false): InfoClientBase | InfoClient => { //TODO: possiamo definire un modello nei form?
    return {
        indirizzo: clientDataForm.address,
        cap: clientDataForm.cap ?? undefined,
        cellulare: findContacts(clientDataForm.contacts, ContactsEnum.Phone) ?? '',
        email: findContacts(clientDataForm.contacts, ContactsEnum.Email) ?? '',
        fax: findContacts(clientDataForm.contacts, ContactsEnum.Fax),
        sito_web: findContacts(clientDataForm.contacts, ContactsEnum.WebSite),
        nominativo: clientDataForm.nominative,
        birthday: moment(clientDataForm.dateOfBirth),
        id_cliente: full ? clientDataForm.code : undefined,
        colore_semaforo: clientDataForm.color,
        creationDate: moment(),
        comune: clientDataForm.city,
        note: clientDataForm.note,
        soundex: undefined,
        type: undefined,
        //TODO: gestire gli altri campi opzionali anche dalla configurazione del form
    }
}

export const createLocationDataByForm = (locationDataForm: any, full: boolean = false): LocationBase | LocationModel => { //TODO: possiamo definire un modello nei form?
    return {
        indirizzo: locationDataForm.address,
        nome: locationDataForm.name,
        identificativo_operatore: locationDataForm.operator,
        id_sede: full ? locationDataForm.locationId : undefined,
        latitudine: locationDataForm.latitude,
        longitudine: locationDataForm.longitude
    }
}

const findContacts = (contacts: any[], contactType: ContactsEnum): ContactsEnum | undefined => {
    if (contacts) {
        const contactFound = contacts.find(el => el.contactType === contactType)
        if (contactFound) return contactFound.contact
    }
}

export const createUserDataByForm = (userDataForm: any, full: boolean = false): UserModelBase | UserModel => { //TODO: possiamo definire un modello nei form?
    const locations: string[] = []
    for (let [key, value] of Object.entries(userDataForm.locations)) {
        if (value) {
            locations.push(key)
        }
    }
    return {
        nome: userDataForm.nome,
        cognome: userDataForm.cognome,
        is_active: userDataForm.active,
        id_utente: full ? userDataForm.code : undefined,
        email: findContacts(userDataForm.contacts, ContactsEnum.Email) ?? '',
        mobilenumber: findContacts(userDataForm.contacts, ContactsEnum.Phone) ?? '',
        sedi: locations,
        tipo: UserType.USER,
        password: userDataForm.password
    }
}

export const createPublisherDataByForm = (clientDataForm: any, full: boolean = false): PublisherBase | Publisher => { //TODO: possiamo definire un modello nei form?
    return {
        indirizzo: clientDataForm.address,
        cap: clientDataForm.cap ?? undefined,
        telefono: findContacts(clientDataForm.contacts, ContactsEnum.Phone),
        email: findContacts(clientDataForm.contacts, ContactsEnum.Email),
        fax: findContacts(clientDataForm.contacts, ContactsEnum.Fax),
        website: findContacts(clientDataForm.contacts, ContactsEnum.WebSite),
        nominativo: clientDataForm.nominative,
        id: full ? clientDataForm.code : undefined,
        citta: clientDataForm.city,
    }
}

export const createProviderDataByForm = (clientDataForm: any, full: boolean = false): ProvidersBase | Providers => { //TODO: possiamo definire un modello nei form?
    return {
        indirizzo: clientDataForm.address,
        cap: clientDataForm.cap ?? undefined,
        telefono: findContacts(clientDataForm.contacts, ContactsEnum.Phone),
        email: findContacts(clientDataForm.contacts, ContactsEnum.Email),
        fax: findContacts(clientDataForm.contacts, ContactsEnum.Fax),
        website: findContacts(clientDataForm.contacts, ContactsEnum.WebSite),
        nominativo: clientDataForm.nominative,
        id: full ? clientDataForm.code : undefined,
        citta: clientDataForm.city,
    }
}