import styled from 'styled-components';

const Layout = styled.div`
    &.withMenu {
        display: flex;
        height: 100vh;
        overflow: hidden;
        section + div {
            width: 100%;
            padding: 15px;
            height: 100vh;
            overflow: auto;
            .headerSection {
                display: flex;
                justify-content: space-between;
            }
        }
    }
    .alert {
        top: 10px;
        left: 10px;
        width: 300px;
        position: absolute;
    }
    .searchPage {
        form {
            border-width: 0 0 1px 0;
            box-shadow: none;
        }
    }
    .width100 {
        width: 100%;
    }
`;

export { Layout };
