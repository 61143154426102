import TableX from "../components/table/table";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { utentiColumns } from "../tables/utenti";
import React from "react";
import { UserModel } from "../models";
import { getAllUsers } from "../api/users";
import { setUsers } from "../redux/slices/user/user";
import { useDispatch } from "react-redux";

const UsersPage = () => {
  const dispatch = useDispatch();
  const [users, setLocalUsers] = React.useState<UserModel[]>([]);

  const init = async () => {
    const res = await getAllUsers();
    setLocalUsers(res.data);
    dispatch(setUsers(res.data));
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="headerSection">
        <h3>Lista Utenti</h3>
        <Link to="/utenti/nuovo" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> Nuovo Utente
        </Link>
      </div>
      <TableX header={utentiColumns} data={users} />
    </>
  );
};

export default UsersPage;
