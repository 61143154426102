import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import clientsForm from "../forms/clients.json";
import clientsUi from "../forms/clientsUi.json";
import PurchaseHistory from "../components/history/purchaseHistory";
import CardClient from "../components/cards/cardClient";
import { createClientDataByForm } from "../utils";
import React from "react";
import { AlertType, InfoClientBase } from "../models";
import { addClient } from "../api/client";
import Alert from "../components/alert/alert";
import Spinner from "../components/spinner/spinner";

const ClientsAddPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );

  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const onDismiss = () => {
    setDismiss(!dismiss);
    navigate("/clienti");
  };

  const handleAddClient = async (clientData: InfoClientBase) => {
    if (clientData) {
      try {
        const clientDataToAdd = createClientDataByForm(
          clientData
        ) as InfoClientBase;
        setLoading(true);
        const res = await addClient(clientDataToAdd);
        if (res.data && res.data.nominativo && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Cliente non aggiunto`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica cliente" : "Inserisci nuovo cliente"}</h3>
        <Link to="/clienti" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={clientsForm}
        ui={clientsUi}
        onAction={handleAddClient}
        submitLabel={"Salva"}
      />
      {params.id ? (
        <>
          <PurchaseHistory />
          <CardClient clientId={params.id} />
        </>
      ) : null}
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={"Cliente aggiunto"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default ClientsAddPage;
