import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	:root {
		--blue: #0061a8;
		--green: #d7dd27;
		--red: #ec1a23;
		--accent: #a5ec1a;
	}
	.btn-primary {
		background-color: var(--blue);
	}
	.btn-info {
		background-color: var(--green);
		color: #000;
		border-color: var(--green);
		&:hover, &:focus {
			background-color: var(--green);
		}
		&:focus {
			box-shadow: 0 0 0 0.25rem var(--green);
		}
	}
	.btn-danger {
		background-color: var(--red);
	}

	.modal {
		display: block;
	}
`;

export default GlobalStyle;
