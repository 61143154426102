import TableX from "../components/table/table";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Publisher } from "../models";
import { useDispatch } from "react-redux";
import { getAllPublishers } from "../api/publishers";
import { setPublishers } from "../redux/slices/publisher/publisher";
import { editoriColumns } from "../tables/publisher";
import Spinner from "../components/spinner/spinner";

const PublishersPage = () => {
  const dispatch = useDispatch();
  const [publishers, setLocalPublishers] = React.useState<Publisher[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const init = async () => {
    setLoading(true);
    const res = await getAllPublishers();
    setLocalPublishers(res.data);
    dispatch(setPublishers(res.data));
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>Lista Editori</h3>
        <Link to="/editori/nuovo" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> Nuovo Editore
        </Link>
      </div>
      <TableX header={editoriColumns} data={publishers} />
    </>
  );
};

export default PublishersPage;
