import { SpinnerStyle } from "./style";

const Spinner = () => {
    return (
        <SpinnerStyle>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </SpinnerStyle>
    );
};

export default Spinner;
