import axios from "axios"
import { ReturnCallbackType, UserModel, UserModelBase } from "../../models"
import { getHeaderInfo, URL_USERS } from "../utils"

export const getAllUsers = async (): Promise<ReturnCallbackType<UserModel[]>> => {
    const result: ReturnCallbackType<UserModel[]> = await axios.get(URL_USERS, {
        headers: getHeaderInfo()
    })
    return result
}

export const addUser = async (data: UserModelBase): Promise<ReturnCallbackType<UserModel | null>> => {
    const result: ReturnCallbackType<UserModel | null> = await axios.post(URL_USERS, data,
        {
            headers: getHeaderInfo(),
        }
    )
    return result
}

export const updateUser = async (data: UserModel): Promise<ReturnCallbackType<UserModel | null>> => {
    const result: ReturnCallbackType<UserModel | null> = await axios.put(URL_USERS, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const deleteUser = async (userId: string): Promise<ReturnCallbackType<boolean>> => {
    const result: ReturnCallbackType<boolean> = await axios.delete(URL_USERS, {
        headers: getHeaderInfo(),
        params: { id_utente: userId },
    })
    return result
}

