import axios from "axios"
import { LocationBase, LocationModel, ReturnCallbackType } from "../../models"
import { getHeaderInfo, URL_LOCATIONS } from "../utils"

export const addLocation = async (data: LocationBase): Promise<ReturnCallbackType<LocationModel | null>> => {
    const result: ReturnCallbackType<LocationModel | null> = await axios.post(URL_LOCATIONS, data,
        {
            headers: getHeaderInfo(),
        }
    )
    return result
}

export const updateLocation = async (data: LocationModel): Promise<ReturnCallbackType<LocationModel | null>> => {
    const result: ReturnCallbackType<LocationModel | null> = await axios.put(URL_LOCATIONS, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const getLocations = async (): Promise<ReturnCallbackType<LocationModel[]>> => {
    const result: ReturnCallbackType<LocationModel[]> = await axios.get(URL_LOCATIONS, {
        headers: getHeaderInfo()
    })
    return result
}

export const deleteLocation = async (locationId: string): Promise<ReturnCallbackType<boolean>> => {
    const result: ReturnCallbackType<boolean> = await axios.delete(URL_LOCATIONS, {
        headers: getHeaderInfo(),
        params: { id_sede: locationId },
    })
    return result
}
