import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserModel } from '../../../models'

export interface UserState {
    surname: string,
    name: string,
    email: string,
    users: UserModel[]
}

const initialState: UserState = {
    surname: '',
    name: '',
    email: '',
    users: []
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {

        setUserValue: (state, action: PayloadAction<UserModel>) => {
            state.email = action.payload.email
            state.name = action.payload.nome
            state.surname = action.payload.cognome
        },
        setUsers: (state, action: PayloadAction<UserModel[]>) => {
            state.users = action.payload
        },
        updateUsersSelectors: (state, action: PayloadAction<UserModel>) => {
            state.users = state.users.map(el => {
                if (el.id_utente === action.payload.id_utente) {
                    return action.payload
                }
                else return el
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserValue, setUsers, updateUsersSelectors } = userSlice.actions

export default userSlice.reducer