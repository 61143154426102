import axios from "axios"
import { ReturnCallbackType } from "../../models"
import { Publisher, PublisherBase } from "../../models/publisher/publisher"
import { getHeaderInfo, URL_PUBLISHERS } from "../utils"

export const getAllPublishers = async (): Promise<ReturnCallbackType<Publisher[]>> => {
    const result: ReturnCallbackType<Publisher[]> = await axios.get(URL_PUBLISHERS, {
        headers: getHeaderInfo()
    })
    return result
}

export const addPublisher = async (data: PublisherBase): Promise<ReturnCallbackType<Publisher | null>> => {
    const result: ReturnCallbackType<Publisher | null> = await axios.post(URL_PUBLISHERS, data,
        {
            headers: getHeaderInfo(),
        }
    )
    return result
}

export const updatePublisher = async (data: Publisher): Promise<ReturnCallbackType<Publisher | null>> => {
    const result: ReturnCallbackType<Publisher | null> = await axios.put(URL_PUBLISHERS, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const deletePublisher = async (publisherId: string): Promise<ReturnCallbackType<boolean>> => {
    const result: ReturnCallbackType<boolean> = await axios.delete(URL_PUBLISHERS, {
        headers: getHeaderInfo(),
        params: { id: publisherId },
    })
    return result
}

