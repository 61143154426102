import TableX from "../components/table/table";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Providers } from "../models";
import { useDispatch } from "react-redux";
import { getAllProviders } from "../api/providers";
import { setProviders } from "../redux/slices/providers/providers";
import Spinner from "../components/spinner/spinner";
import { fornitoriColumns } from "../tables/providers";

const ProvidersPage = () => {
  const dispatch = useDispatch();
  const [providers, setLocalProviderss] = React.useState<Providers[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const init = async () => {
    setLoading(true);
    const res = await getAllProviders();
    setLocalProviderss(res.data);
    dispatch(setProviders(res.data));
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>Lista Fornitori</h3>
        <Link to="/fornitori/nuovo" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> Nuovo Fornitore
        </Link>
      </div>
      <TableX header={fornitoriColumns} data={providers} />
    </>
  );
};

export default ProvidersPage;
