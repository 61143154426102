import { Link } from 'react-router-dom';

const CardClient = (props: {clientId?: number | string}) => {

    const toBeWithdrawn = props.clientId ? [
        {
            userName: 'Roberto Capasso',
            userId: 1,
            comics: ['berserk', 'Spiderman'],
        }
    ] : [
        {
            userName: 'Roberto Capasso',
            userId: 1,
            comics: ['berserk', 'Spiderman'],
        },
        {
            userName: 'Aniello Prisco',
            userId: 2,
            comics: ['Naruto', 'One piece', 'Slam Dunk'],
        },
        {
            userName: 'Antonio Squillante',
            userId: 3,
            comics: ['Batman', 'Fantastici 4', 'Lanterna Verde'],
        },
    ];

    return (
            <div className="container-fluid px-4 py-5">
                <h2 className="pb-2 border-bottom">Fumetti da ritirare</h2>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    {toBeWithdrawn.map((withdraw) => (
                        <div className="col d-flex align-items-start">
                            <div className="width100">
                                <h5 className="fs-4 text-truncate">
                                    {withdraw.userName}
                                </h5>
                                <hr />
                                <p>{withdraw.comics.join(', ')}</p>
                                <Link
                                    to={'/clienti/modifica/' + withdraw.userId}
                                    className="btn btn-primary"
                                >
                                    Vedi dettaglio
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default CardClient;
