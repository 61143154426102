import axios from "axios"
import { ReturnCallbackType } from "../../models"
import { Providers, ProvidersBase } from "../../models"
import { getHeaderInfo, URL_PROVIDERS } from "../utils"

export const getAllProviders = async (): Promise<ReturnCallbackType<Providers[]>> => {
    const result: ReturnCallbackType<Providers[]> = await axios.get(URL_PROVIDERS, {
        headers: getHeaderInfo()
    })
    return result
}

export const addProvider = async (data: ProvidersBase): Promise<ReturnCallbackType<Providers | null>> => {
    const result: ReturnCallbackType<Providers | null> = await axios.post(URL_PROVIDERS, data,
        {
            headers: getHeaderInfo(),
        }
    )
    return result
}

export const updateProvider = async (data: Providers): Promise<ReturnCallbackType<Providers | null>> => {
    const result: ReturnCallbackType<Providers | null> = await axios.put(URL_PROVIDERS, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const deleteProvider = async (publisherId: string): Promise<ReturnCallbackType<boolean>> => {
    const result: ReturnCallbackType<boolean> = await axios.delete(URL_PROVIDERS, {
        headers: getHeaderInfo(),
        params: { id: publisherId },
    })
    return result
}

