import React from "react";
import { authLogin } from "../../api/login";
import { LoginData } from "../../models/index";
import Alert from "../alert/alert";
import { AlertType } from "../../models/alert/alert";
import Spinner from "../spinner/spinner";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logoPanel.png";
import { SectionLogin } from "./style";
import { useDispatch } from "react-redux";
import { setUserValue } from "../../redux/slices/user/user";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [wrongCredential, setWrongCredential] = React.useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dismiss, setDismiss] = React.useState<boolean>(false);

  React.useEffect(() => {
    setWrongCredential(undefined);
  }, [dismiss]);

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
    event.preventDefault();
  };

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
    event.preventDefault();
  };

  const onSubmit = async () => {
    if (!username || !password) {
      setWrongCredential("Inserisci email e password");
    }
    if (password && username) {
      setWrongCredential(undefined);
      const loginData: LoginData = {
        password: password,
        username: username,
      };
      setLoading(true);
      try {
        const res = await authLogin(loginData);

        localStorage.setItem("X-Token", res.headers["x-token"]);
        if (res.data && res.status === 202) {
          const usernameSaved = localStorage.getItem("username");
          if (!usernameSaved || usernameSaved !== res.data.email) {
            localStorage.setItem("username", res.data.email);
          }
          dispatch(setUserValue(res.data));
          navigate("/dashboard");
          setLoading(false);
        } else {
          setWrongCredential(`Utente non trovato`);
        }
      } catch (err: any) {
        console.log("err", err);
        setWrongCredential(err.message);
        setLoading(false);
      }
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <SectionLogin className="vh-100">
      <center>
        <img src={logo} alt="logo panel" />
      </center>
      <div className="container py-5">
        <div className="row d-flex justify-content-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card shadow-2-strong"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center">
                <h3 className="mb-5">Inserisci i dati per accedere</h3>

                <div className="form-outline mb-4">
                  <input
                    type="username"
                    id="typeEmailX-2"
                    className="form-control form-control-lg"
                    onChange={onChangeEmail}
                    value={username}
                  />
                  <label className="form-label">Email</label>
                </div>

                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="typePasswordX-2"
                    className="form-control form-control-lg"
                    onChange={onChangePassword}
                    value={password}
                  />
                  <label className="form-label">Password</label>
                </div>

                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  onClick={onSubmit}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wrongCredential ? (
        <Alert
          type={AlertType.danger}
          message={wrongCredential}
          onDismiss={onDismiss}
        />
      ) : null}
    </SectionLogin>
  );
};

export default Login;
