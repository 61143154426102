import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { store } from '../src/redux/store';
import { Provider } from 'react-redux';
import Index from './views';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <Index />
            </BrowserRouter>
        </Provider>
    );
} else {
    alert('Panic: a fatal error has occurred, please try again later');
    console.error('Panic');
}
