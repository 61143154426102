import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { UserModel } from "../models";

const getContacts = (row: UserModel) => {
  const contacts: any = [];
  if (row.mobilenumber) {
    contacts.push(<div>{row.mobilenumber}</div>);
  }
  if (row.email) {
    contacts.push(<div>{row.email}</div>);
  }
  return contacts;
};

export const utentiColumns = [
  {
    name: "Nome",
    selector: (row: UserModel) => row.nome,
  },
  {
    name: "Cognome",
    selector: (row: UserModel) => row.cognome,
  },
  {
    name: "Contatti",
    selector: (row: UserModel) => getContacts(row),
  },
  {
    name: "",
    selector: (row: any) => (
      <div className="table-actions">
        <Link
          to={"/utenti/modifica/" + row.id_utente}
          className="btn btn-primary"
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        <Link
          to={"/utenti/delete/" + row.id_utente + "/" + row.nome}
          className="btn btn-danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      </div>
    ),
  },
];
