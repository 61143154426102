import React from "react";
import { AlertType } from "../../models/alert/alert";

interface ToastProps {
  type: AlertType;
  message: string;
  onDismiss: () => void;
}

const Alert = (props: ToastProps) => {
  const [rendered, setRendered] = React.useState<JSX.Element | undefined>();

  React.useEffect(() => {
    const res = chooseAlertToRender();
    setRendered(res);
  }, []);

  const chooseAlertToRender = () => {
    switch (props.type) {
      case AlertType.danger:
        return (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            {props.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={props.onDismiss}
            ></button>
          </div>
        );
      case AlertType.info:
        return (
          <div
            className="alert alert-info alert-dismissible fade show"
            role="alert"
          >
            {props.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={props.onDismiss}
            ></button>
          </div>
        );
      case AlertType.success:
        return (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            {props.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={props.onDismiss}
            ></button>
          </div>
        );
      case AlertType.warning:
        return (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            {props.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={props.onDismiss}
            ></button>
          </div>
        );
    }
  };

  return rendered ? rendered : null;
};

export default Alert;
