export interface UserModel extends UserModelBase {
    id_utente: string
}

export interface UserModelBase {
    nome: string,
    cognome: string,
    email: string
    mobilenumber: string
    tipo: UserType,
    is_active: ActiveUser,
    sedi: string[],
    password: string,
    pass_word?: string //TODO: mi serve perchè il db mi ritorna ancora questo valore
}

export enum UserType {
    USER = "USER"
}

export enum ActiveUser {
    INATTIVO,
    ATTIVO
}