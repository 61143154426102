import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LocationModel } from '../../../models'

interface LocationsState {
    locations: LocationModel[]
}


const initialState: LocationsState = {
    locations: []
}

export const locationsSlice = createSlice({
    name: 'locationsSlice',
    initialState,
    reducers: {
        setLocations: (state, action: PayloadAction<LocationModel[]>) => {
            state.locations = action.payload
        },
        updateLocationsSelectors: (state, action: PayloadAction<LocationModel>) => {
            state.locations = state.locations.map(el => {
                if (el.id_sede === action.payload.id_sede) {
                    return action.payload
                }
                else return el
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const { setLocations, updateLocationsSelectors } = locationsSlice.actions

export default locationsSlice.reducer