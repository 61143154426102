import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import locationsForm from "../forms/locations.json";
import { AlertType, LocationBase, LocationModel } from "../models";
import { createLocationDataByForm } from "../utils";
import React, { useEffect } from "react";
import { addLocation, getLocations, updateLocation } from "../api/locations";
import Spinner from "../components/spinner/spinner";
import Alert from "../components/alert/alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setLocations,
  updateLocationsSelectors,
} from "../redux/slices/locations/locations";

const LocationsEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectorLocations = useSelector(
    (state: RootState) => state.location.locations
  );
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );
  const [formDataValue, setFormData] = React.useState<any>(undefined);

  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const handleAddLocation = async (locationBase: LocationBase) => {
    if (locationBase) {
      try {
        const locationToAdd = createLocationDataByForm(
          locationBase
        ) as LocationBase;
        setLoading(true);
        const res = await addLocation(locationToAdd);
        if (res.data && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Sede non aggiunta`);
        }
        setLoading(false);
        navigate("/sedi");
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const locationToUpdate = React.useMemo(
    () => selectorLocations.find((loc) => loc.id_sede === params.id),
    [params.id, selectorLocations]
  );

  const handleEditLocation = async (locationToUpdate: LocationModel) => {
    if (locationToUpdate) {
      const locationDataToUpdate = createLocationDataByForm(
        locationToUpdate,
        true
      ) as LocationModel;
      try {
        setLoading(true);
        const res = await updateLocation(locationDataToUpdate);
        if (res.data && res.status === 200) {
          setMessage("success");
          dispatch(updateLocationsSelectors(res.data));
        } else {
          setMessage(`Sede non modificata`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const getDefaultFormData = React.useCallback(() => {
    if (locationToUpdate) {
      return {
        locationId: locationToUpdate.id_sede,
        operator: parseInt(locationToUpdate.identificativo_operatore),
        address: locationToUpdate.indirizzo,
        latitude: locationToUpdate.latitudine ?? undefined,
        longitude: locationToUpdate.longitudine ?? undefined,
        name: locationToUpdate.nome,
      };
    }
  }, [locationToUpdate]);

  const getLocationFromDb = async () => {
    const locationsOnDb = await getLocations();
    dispatch(setLocations(locationsOnDb.data));
  };

  useEffect(() => {
    if (locationToUpdate) {
      const defaultForm = getDefaultFormData();
      if (defaultForm) {
        setFormData(defaultForm);
      }
    } else {
      getLocationFromDb();
    }
  }, [locationToUpdate, getDefaultFormData, params.id]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica sede" : "Inserisci nuova sede"}</h3>
        <Link to="/sedi" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={locationsForm}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={params.id ? handleEditLocation : handleAddLocation}
        formData={formDataValue}
      />
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={params.id ? "Sede modificata" : "Sede aggiunta"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default LocationsEditPage;
