import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

export const sediColumns = [
  {
    name: "Indirizzo",
    selector: (row: any) => row.indirizzo,
  },
  {
    name: "Utenti",
    selector: (row: any) => row.identificativo_operatore,
  },
  {
    name: "",
    selector: (row: any) => (
      <div className="table-actions">
        <Link to={"/sedi/modifica/" + row.id_sede} className="btn btn-primary">
          <FontAwesomeIcon icon={faPen} />
        </Link>
        <Link
          to={"/sedi/delete/" + row.id_sede + "/" + row.nome}
          className="btn btn-danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      </div>
    ),
  },
];
