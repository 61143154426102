import FormX from "../components/form/form";
import clientsSearch from "../forms/clientsSearch.json";
import TableX from "../components/table/table";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { clientiColumns } from "../tables/clienti";
import React from "react";
import { InfoClient } from "../models";
import { getClientsByName } from "../api/client";
import Spinner from "../components/spinner/spinner";
import { setClients } from "../redux/slices/clients/clients";
import { useDispatch } from "react-redux";

const ClientsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clientsFromSearch, setClientsFromSearch] = React.useState<
    InfoClient[] | undefined
  >(undefined);

  const getClientsByNominative = async (formData: any) => {
    if (formData && formData.nominative) {
      setLoading(true);
      const result = await getClientsByName(formData.nominative);
      setClientsFromSearch(result.data);
      dispatch(setClients(result.data));
      setLoading(false);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="searchPage">
      <div className="headerSection">
        <h3>Clienti</h3>
        <Link to="/clienti/nuovo" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> nuovo cliente
        </Link>
      </div>
      <FormX
        form={clientsSearch}
        onAction={getClientsByNominative}
        submitLabel="Cerca"
      />
      {clientsFromSearch ? (
        clientsFromSearch.length > 0 ? (
          <TableX header={clientiColumns} data={clientsFromSearch} />
        ) : (
          <p>Nessun cliente trovato</p>
        )
      ) : null}
    </div>
  );
};

export default ClientsPage;
