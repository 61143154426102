import styled from 'styled-components';

const TableStyle = styled.div`
    .table-actions .btn {
        width: 24px;
        height: 24px;
        font-size: 12px;
        padding: 1px;
        margin-right: 4px;
    }
    .stoplight {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        &.stoplight-green {
            background: var(--accent);
        }
        &.stoplight-yellow {
            background: yellow;
        }
        &.stoplight-red {
            background: var(--red);
        }
    }
`;

export default TableStyle;
