import { LoginData } from "../../models/index";
import axios from "axios";
import { UserModel } from "../../models/user/user";
import { ReturnCallbackType } from "../../models/common";
import { getHeaderInfo, URL_LOGIN } from "../utils";

export const authLogin = async (data: LoginData): Promise<ReturnCallbackType<UserModel | null>> => {
    const result: ReturnCallbackType<UserModel | null> = await axios.post(URL_LOGIN, {
        username: data.username,
        password: data.password,
    }, {
        headers: getHeaderInfo(),
    })
    return result
}