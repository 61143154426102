import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import providersForm from "../forms/providers.json";
import providersUI from "../forms/providersUI.json";
import React from "react";
import { AlertType, ContactsEnum, Providers, ProvidersBase } from "../models";
import { createProviderDataByForm } from "../utils";
import { addProvider, getAllProviders, updateProvider } from "../api/providers";
import {
  setProviders,
  updateProvidersSelectors,
} from "../redux/slices/providers/providers";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/spinner/spinner";
import { RootState } from "../redux/store";
import Alert from "../components/alert/alert";

const ProviderEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const selectorProviders = useSelector(
    (state: RootState) => state.providers.providers
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );
  const [formDataValue, setFormData] = React.useState<any>(undefined);
  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const providersToUpdate = React.useMemo(
    () => selectorProviders.find((providers) => providers.id === params.id),
    [params.id, selectorProviders]
  );

  const getDefaultFormData = React.useCallback(() => {
    try {
      if (providersToUpdate) {
        const contacts: { contactType: ContactsEnum; contact: string }[] = [];
        if (providersToUpdate.telefono) {
          contacts.push({
            contactType: ContactsEnum.Phone,
            contact: providersToUpdate.telefono,
          });
        }
        if (providersToUpdate.email) {
          contacts.push({
            contactType: ContactsEnum.Email,
            contact: providersToUpdate.email,
          });
        }
        if (providersToUpdate.fax) {
          contacts.push({
            contactType: ContactsEnum.Fax,
            contact: providersToUpdate.fax,
          });
        }
        if (providersToUpdate.website) {
          contacts.push({
            contactType: ContactsEnum.WebSite,
            contact: providersToUpdate.website,
          });
        }
        return {
          code: providersToUpdate.id,
          nominative: providersToUpdate.nominativo,
          city: providersToUpdate.citta ?? undefined,
          cap: providersToUpdate.cap
            ? parseInt(providersToUpdate.cap)
            : undefined,
          address: providersToUpdate.indirizzo,
          contacts: contacts,
        };
      }
    } catch (err) {
      console.log("err getDefaultFormData providers", err);
    }
  }, [providersToUpdate]);

  const handleAddProvider = async (providersBase: ProvidersBase) => {
    if (providersBase) {
      try {
        const providersToAdd = createProviderDataByForm(
          providersBase
        ) as ProvidersBase;
        setLoading(true);
        const res = await addProvider(providersToAdd);
        if (res.data && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Fornitore non aggiunto`);
        }
        setLoading(false);
        navigate("/fornitori");
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const handleEditProvider = async (providersToUpdate: Providers) => {
    if (providersToUpdate) {
      const providersDataToUpdate = createProviderDataByForm(
        providersToUpdate,
        true
      ) as Providers;
      try {
        setLoading(true);
        const res = await updateProvider(providersDataToUpdate);
        console.log("providers Updated", res);
        if (res.data && res.status === 200) {
          setMessage("success");
          dispatch(updateProvidersSelectors(res.data));
        } else {
          setMessage(`Fornitore non modificato`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const getProvidersFromDb = React.useCallback(async () => {
    const providersOnDb = await getAllProviders();
    dispatch(setProviders(providersOnDb.data));
  }, [dispatch]);

  React.useEffect(() => {
    if (providersToUpdate) {
      const defaultForm = getDefaultFormData();
      if (defaultForm) {
        setFormData(defaultForm);
      }
    } else {
      getProvidersFromDb();
    }
  }, [getDefaultFormData, providersToUpdate, params.id, getProvidersFromDb]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>
          {params.id ? "Modifica fornitore" : "Inserisci nuovo fornitore"}
        </h3>
        <Link to="/fornitori" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={providersForm}
        ui={providersUI}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={params.id ? handleEditProvider : handleAddProvider}
        formData={formDataValue}
      />
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={params.id ? "Fornitore modificato" : "Fornitore aggiunto"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default ProviderEditPage;
