import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { Providers } from "../models";

const getContacts = (row: Providers) => {
  const contacts: any = [];
  if (row.telefono) {
    contacts.push(<div>{row.telefono}</div>);
  }
  if (row.email) {
    contacts.push(<div>{row.email}</div>);
  }
  if (row.website) {
    contacts.push(<div>{row.website}</div>);
  }
  if (row.fax) {
    contacts.push(<div>{row.fax}</div>);
  }
  return contacts;
};

export const fornitoriColumns = [
  {
    name: "Nome",
    selector: (row: Providers) => row.nominativo,
  },
  {
    name: "Contatti",
    selector: (row: Providers) => getContacts(row),
  },
  {
    name: "",
    selector: (row: any) => (
      <div className="table-actions">
        <Link to={"/fornitori/modifica/" + row.id} className="btn btn-primary">
          <FontAwesomeIcon icon={faPen} />
        </Link>
        <Link
          to={"/fornitori/delete/" + row.id + "/" + row.nominativo}
          className="btn btn-danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      </div>
    ),
  },
];
