import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deletePublisher } from "../api/publishers";
import Modal from "../components/modal/modal";
import Spinner from "../components/spinner/spinner";

const PublishersDelete = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const onDelete = async () => {
    if (params.id) {
      setLoading(true);
      await deletePublisher(params.id);
      setLoading(false);
      navigate("/editori");
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Modal
      title="Cancella editore"
      message={`Sei sicuro di cancellare questo editore con nome ${params.name} e con id ${params.id}?`}
      onDelete={onDelete}
      urlBack="/editori"
    />
  );
};

export default PublishersDelete;
