import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

export const articlesColumns = [
  {
    name: "Nome",
    selector: (row: any) => row.name,
  },
  {
    name: "Publisher",
    selector: (row: any) => row.publisher,
  },
  {
    name: "Categoria",
    selector: (row: any) => row.category,
  },
  {
    name: "Isbn",
    selector: (row: any) => row.isbn,
  },
  {
    name: "Prezzo",
    selector: (row: any) => row.price,
  },
  {
    name: "Descrizione",
    selector: (row: any) => row.description,
  },
  {
    name: "Quantità",
    selector: (row: any) => row.quantity,
  },
  {
    name: "Quantità impegnata",
    selector: (row: any) => row.quantityAllowed,
  },
  {
    name: "Quantità ordinata",
    selector: (row: any) => row.quantityOrdered,
  },
  {
    name: "Quantità disponibile",
    selector: (row: any) => row.quantityAvaiable,
  },
  {
    name: "Quantità Deposito",
    selector: (row: any) => row.quantityInSite,
  },
  {
    name: "Editore",
    selector: (row: any) => row.editor,
  },
  {
    name: "Collana",
    selector: (row: any) => row.collana,
  },
  {
    name: "Numero interno collana",
    selector: (row: any) => row.collanaNumber,
  },
  {
    name: "Testata",
    selector: (row: any) => row.testata,
  },
  {
    name: "Numero interno testate",
    selector: (row: any) => row.testataNumber,
  },
  {
    name: "Codice interno",
    selector: (row: any) => row.internalCode,
  },
  {
    name: "Locazione",
    selector: (row: any) => row.locazione,
  },
  {
    name: "Scorta minima",
    selector: (row: any) => row.scortaMinima,
  },
  {
    name: "Iva",
    selector: (row: any) => row.iva,
  },
  {
    name: "Stato",
    selector: (row: any) => row.state,
  },
  {
    name: "Note",
    selector: (row: any) => row.note,
  },
  {
    name: "Sconto",
    selector: (row: any) => row.discount,
  },
  {
    name: "Soundex",
    selector: (row: any) => row.soundex,
  },
  {
    name: "",
    selector: (row: any) => (
      <div className="table-actions">
        <Link to={"/articles/modifica/" + row.id} className="btn btn-primary">
          <FontAwesomeIcon icon={faPen} />
        </Link>
        <Link to={"/articles/modifica/" + row.id} className="btn btn-danger">
          <FontAwesomeIcon icon={faTrash} />
        </Link>
      </div>
    ),
  },
];
