import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import { FormXStyle } from "./styleForm";

const FormX = (props: {
  form: any;
  formData?: any;
  onAction?: (formData: any) => void;
  submitLabel: string;
  ui?: any;
}) => {
  const log = (type: string) => console.log(console, type);

  return (
    <FormXStyle className="container-fluid">
      <div className="row">
        <div className="col">
          <Form
            schema={props.form}
            formData={props.formData}
            validator={validator}
            uiSchema={props.ui}
            // onChange={() => log("changed")}
            onSubmit={(e) => {
              if (props.onAction) {
                props.onAction(e.formData);
              }
            }}
            onError={() => log("errors")}
          >
            <div>
              <button type="submit" className="btn btn-info">
                {props.submitLabel}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </FormXStyle>
  );
};

export default FormX;
