import styled from 'styled-components';

export const SideMenuStyle = styled.section`
    width: 280px;
    background: var(--green);
    box-shadow: 2px 2px 4px var(--blue);
    img {
        width: 100px;
        cursor: pointer;
    }
    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
    }
    .open {
        button svg {
            transform: rotate(180deg);
        }
        .collapse {
            display: block;
        }
    }
    .btn-menu {
        width: 100%;
        text-align: left;
        padding: 5px 0 2px 0;
        border-bottom: 1px solid var(--blue);
        border-radius: 0;
        outline: none !important;
        box-shadow: none;
        svg {
            float: right;
            color: var(--blue);
            margin-top: 5px;
        }
        & + * ul {
            padding-left: 8px;
            a {
                color: var(--blue);
                text-decoration: none;
                margin: 3px 0;
                display: block;
                font-size: 14px;
                svg {
                    margin-right: 5px;
                    width: 20px;
                }
            }
        }
    }
`;
