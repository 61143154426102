import axios from "axios"
import { Articles, ArticlesBase, ReturnCallbackType } from "../../models"
import { getHeaderInfo, URL_ARTICLE } from "../utils"


export const addArticle = async (data: ArticlesBase): Promise<ReturnCallbackType<ArticlesBase | null>> => { //TODO: verrà sostituita con una get di tutti i clienti che devono ritirare oggi
    const result: ReturnCallbackType<ArticlesBase | null> = await axios.post(URL_ARTICLE, data,
        {
            headers: getHeaderInfo(),
        }

    )
    return result
}

export const updateArticle = async (data: Articles): Promise<ReturnCallbackType<Articles | null>> => {
    const result: ReturnCallbackType<Articles | null> = await axios.put(URL_ARTICLE, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const getAllArticles = async (): Promise<ReturnCallbackType<Articles[]>> => {
    const result: ReturnCallbackType<Articles[]> = await axios.get(URL_ARTICLE, {
        headers: getHeaderInfo()
    })
    return result
}

export const getArticlesByName = async (name: string): Promise<ReturnCallbackType<Articles[]>> => {
    let urlWithPathParameter = URL_ARTICLE + '/' + name
    urlWithPathParameter = urlWithPathParameter.replace(/\s/g, '');
    const result: ReturnCallbackType<Articles[]> = await axios.get(urlWithPathParameter.trim(), {
        headers: getHeaderInfo()
    })
    return result
}

export const getArticlesByPublisher = async (publisher: string): Promise<ReturnCallbackType<Articles[]>> => {
    let urlWithPathParameter = URL_ARTICLE + '/' + publisher
    urlWithPathParameter = urlWithPathParameter.replace(/\s/g, '');
    const result: ReturnCallbackType<Articles[]> = await axios.get(urlWithPathParameter.trim(), {
        headers: getHeaderInfo()
    })
    return result
}

export const getArticlesByNumber = async (numeroTestata: number): Promise<ReturnCallbackType<Articles[]>> => {
    let urlWithPathParameter = URL_ARTICLE + '/' + numeroTestata
    urlWithPathParameter = urlWithPathParameter.replace(/\s/g, '');
    const result: ReturnCallbackType<Articles[]> = await axios.get(urlWithPathParameter.trim(), {
        headers: getHeaderInfo()
    })
    return result
}

export const getArticleByIsbn = async (isbn: number): Promise<ReturnCallbackType<Articles | null>> => {
    let urlWithPathParameter = URL_ARTICLE + '/' + isbn
    urlWithPathParameter = urlWithPathParameter.replace(/\s/g, '');
    const result: ReturnCallbackType<Articles | null> = await axios.get(urlWithPathParameter.trim(), {
        headers: getHeaderInfo()
    })
    return result
}

export const deleteArticles = async (isbn: string): Promise<ReturnCallbackType<boolean>> => {
    const result: ReturnCallbackType<boolean> = await axios.delete(URL_ARTICLE, {
        headers: getHeaderInfo(),
        params: isbn,
    })
    return result
}