import TableX from "../components/table/table";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { articlesColumns } from "../tables/articles";
import { Articles } from "../models";

const mockArticles: Articles = {
  isbn: 12,
  name: "articolo 1",
  price: 22,
  category: "manga",
  collana: "1",
  description: "description",
  editor: "editor",
  iva: 22,
  numeroCollana: 1,
  numeroTestata: 2,
  publisher: "publisher",
  quantity: 10,
  quantityAllowed: 10,
  quantityAvaiable: 21,
  quantityInSite: 1,
  quantityOrdered: 1,
  scortaMinima: 1,
  testata: "testat",
};

const ArticlesPage = () => {
  return (
    <>
      <div className="headerSection">
        <h3>Lista Articoli</h3>
        <Link to="/articoli/nuova" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> nuovo articolo
        </Link>
      </div>
      <TableX header={articlesColumns} data={[mockArticles]} />
    </>
  );
};

export default ArticlesPage;
