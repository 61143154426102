import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { InfoClient } from "../models";
import { deleteClient } from "../api/client";

const getContacts = (row: InfoClient) => {
  const contacts: any = [];
  if (row.cellulare) {
    contacts.push(<div>{row.cellulare}</div>);
  }
  if (row.email) {
    contacts.push(<div>{row.email}</div>);
  }
  if (row.sito_web) {
    contacts.push(<div>{row.sito_web}</div>);
  }
  if (row.fax) {
    contacts.push(<div>{row.fax}</div>);
  }
  return contacts;
};

const handleDeleteClient = async (row: InfoClient) => {
  return deleteClient(row.id_cliente);
};

export const clientiColumns = [
  {
    name: "",
    selector: (row: InfoClient) => {
      if (row.colore_semaforo === "g")
        return <div className="stoplight stoplight-green" />;
      else if (row.colore_semaforo === "y")
        return <div className="stoplight stoplight-yellow" />;
      else return <div className="stoplight stoplight-red" />;
    },
  },
  {
    name: "Nominativo",
    selector: (row: InfoClient) => row.nominativo,
  },

  {
    name: "Indirizzo",
    selector: (row: InfoClient) => row.indirizzo,
  },
  {
    name: "Contatti",
    selector: (row: InfoClient) => getContacts(row),
  },
  {
    name: "",
    selector: (row: InfoClient) => (
      <div className="table-actions">
        <Link
          to={"/clienti/modifica/" + row.id_cliente}
          className="btn btn-primary"
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
      </div>
    ),
  },
];
