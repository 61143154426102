import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import * as ClientsModel from '../../../models'

interface ClientsState {
    currentClient: ClientsModel.InfoClient | undefined,
    clients: ClientsModel.InfoClient[],
    nominativeToFind: string | undefined
}


const initialState: ClientsState = {
    currentClient: undefined,
    clients: [],
    nominativeToFind: undefined
}

export const clientSlice = createSlice({
    name: 'clientSlice',
    initialState,
    reducers: {

        updateClient: (state, action: PayloadAction<ClientsModel.InfoClient>) => {
            state.currentClient = action.payload
        },

        setClients: (state, action: PayloadAction<ClientsModel.InfoClient[]>) => {
            state.clients = action.payload
        },

        updateClientsSelectors: (state, action: PayloadAction<ClientsModel.InfoClient>) => {
            state.clients = state.clients.map(el => {
                if (el.id_cliente === action.payload.id_cliente) {
                    return action.payload
                }
                else return el
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateClient, setClients, updateClientsSelectors } = clientSlice.actions

export default clientSlice.reducer