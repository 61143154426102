import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import usersForm from "../forms/users.json";
import usersUI from "../forms/usersUi.json";
import React from "react";
import { getLocations } from "../api/locations";
import {
  AlertType,
  ContactsEnum,
  LocationModel,
  UserModel,
  UserModelBase,
} from "../models";
import { createUserDataByForm } from "../utils";
import { addUser, getAllUsers, updateUser } from "../api/users";
import { setUsers, updateUsersSelectors } from "../redux/slices/user/user";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/spinner/spinner";
import { RootState } from "../redux/store";
import Alert from "../components/alert/alert";

const UsersEditPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const selectorUsers = useSelector((state: RootState) => state.user.users);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );
  const [formDataValue, setFormData] = React.useState<any>(undefined);
  const [dismiss, setDismiss] = React.useState<boolean>(false);
  const [locationsData, setLocationsData] = React.useState<LocationModel[]>([]);

  const init = async () => {
    const locations = await getLocations();
    setLocationsData(locations.data);
  };

  React.useEffect(() => {
    init();
  }, []);

  const userToUpdate = React.useMemo(
    () => selectorUsers.find((user) => user.id_utente === params.id),
    [params.id, selectorUsers]
  );

  const locationsInForm = React.useMemo(() => {
    const newUsersForm = Object.assign({}, usersForm);
    locationsData.forEach((loc) => {
      if (!params.id) {
        const option = { title: loc.nome, type: "boolean" };
        newUsersForm.properties.locations.properties = {
          ...newUsersForm.properties.locations.properties,
          [loc.id_sede]: option,
        };
      } else {
        const findLoc = userToUpdate?.sedi?.find((el) => el === loc.id_sede);
        if (findLoc) {
          const option = { title: loc.nome, type: "boolean", default: true };
          newUsersForm.properties.locations.properties = {
            ...newUsersForm.properties.locations.properties,
            [loc.id_sede]: option,
          };
        } else {
          const option = { title: loc.nome, type: "boolean" };
          newUsersForm.properties.locations.properties = {
            ...newUsersForm.properties.locations.properties,
            [loc.id_sede]: option,
          };
        }
      }
    });

    return newUsersForm;
  }, [locationsData, params.id, userToUpdate?.sedi]);

  // const getDefaultLocations = (locationsId: string[], loc: LocationModel[]) => {
  //   let obj: any;
  //   for (let idLoc of locationsId) {
  //     const findLoc = loc.find((loc) => loc.id_sede === idLoc);
  //     if (findLoc) {
  //       obj = {
  //         ...obj,
  //         [idLoc]: { title: findLoc.nome, type: "boolean" },
  //       };
  //     }
  //   }
  //   return obj;
  // };

  const getDefaultFormData = React.useCallback(() => {
    try {
      if (userToUpdate) {
        const contacts: { contactType: ContactsEnum; contact: string }[] = [];
        if (userToUpdate.mobilenumber) {
          contacts.push({
            contactType: ContactsEnum.Phone,
            contact: userToUpdate.mobilenumber,
          });
        }
        if (userToUpdate.email) {
          contacts.push({
            contactType: ContactsEnum.Email,
            contact: userToUpdate.email,
          });
        }
        return {
          code: userToUpdate.id_utente,
          nome: userToUpdate.nome,
          cognome: userToUpdate.cognome,
          password: userToUpdate.password ?? userToUpdate.pass_word,
          active: parseInt(userToUpdate.is_active?.toString()),
          contacts: contacts,
        };
      }
    } catch (err) {
      console.log("err getDefaultFormData user", err);
    }
  }, [userToUpdate]);

  const handleAddUser = async (userBase: UserModel) => {
    if (userBase) {
      try {
        const userToAdd = createUserDataByForm(userBase) as UserModelBase;
        setLoading(true);
        const res = await addUser(userToAdd);
        if (res.data && res.status === 201) {
          setMessage("success");
        } else {
          setMessage(`Utente non aggiunta`);
        }
        setLoading(false);
        navigate("/utenti");
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const handleEditUser = async (userToUpdate: UserModel) => {
    if (userToUpdate) {
      const userDataToUpdate = createUserDataByForm(
        userToUpdate,
        true
      ) as UserModel;
      try {
        setLoading(true);
        const res = await updateUser(userDataToUpdate);
        console.log("user Updated", res);
        if (res.data && res.status === 200) {
          setMessage("success");
          dispatch(updateUsersSelectors(res.data));
        } else {
          setMessage(`Utente non modificata`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const onDismiss = () => {
    setDismiss(!dismiss);
  };

  const getUsersFromDb = async () => {
    const usersOnDb = await getAllUsers();
    dispatch(setUsers(usersOnDb.data));
  };

  React.useEffect(() => {
    if (userToUpdate) {
      const defaultForm = getDefaultFormData();
      if (defaultForm) {
        setFormData(defaultForm);
      }
    } else {
      getUsersFromDb();
    }
  }, [getDefaultFormData, userToUpdate, params.id]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica utente" : "Inserisci nuovo utente"}</h3>
        <Link to="/utenti" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={locationsInForm}
        ui={usersUI}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={params.id ? handleEditUser : handleAddUser}
        formData={formDataValue}
      />
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={params.id ? "Sede modificata" : "Sede aggiunta"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default UsersEditPage;
