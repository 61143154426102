import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Publisher } from '../../../models'

interface PublisherState {
    currentPublisher: Publisher | undefined,
    publishers: Publisher[],
}


const initialState: PublisherState = {
    currentPublisher: undefined,
    publishers: [],
}

export const publisherSlice = createSlice({
    name: 'publisherSlice',
    initialState,
    reducers: {

        updatePublisher: (state, action: PayloadAction<Publisher>) => {
            state.currentPublisher = action.payload
        },

        setPublishers: (state, action: PayloadAction<Publisher[]>) => {
            state.publishers = action.payload
        },

        updatePublishersSelectors: (state, action: PayloadAction<Publisher>) => {
            state.publishers = state.publishers.map(el => {
                if (el.id === action.payload.id) {
                    return action.payload
                }
                else return el
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { updatePublisher, setPublishers, updatePublishersSelectors } = publisherSlice.actions

export default publisherSlice.reducer