import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userReducer from './slices/user/user'
import clientReduces from './slices/clients/clients'
import locationReduces from './slices/locations/locations'
import publisherReduces from './slices/publisher/publisher'
import providers from './slices/providers/providers'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

export const store = configureStore({
    reducer: {
        user: userReducer,
        client: clientReduces,
        location: locationReduces,
        publisher: publisherReduces,
        providers: providers
    },
    middleware: customizedMiddleware,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch