import TableX from "../components/table/table";
import { sediColumns } from "../tables/sedi";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { getLocations } from "../api/locations";
import { LocationModel } from "../models";
import { useDispatch } from "react-redux";
import { setLocations } from "../redux/slices/locations/locations";

const LocationsPage = () => {
  const dispatch = useDispatch();
  const [locations, setLocalLocations] = React.useState<LocationModel[]>([]);

  const init = async () => {
    const res = await getLocations();
    setLocalLocations(res.data ?? []);
    dispatch(setLocations(res.data));
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="headerSection">
        <h3>Lista Sedi</h3>
        <Link to="/sedi/nuova" className="btn btn-primary">
          <FontAwesomeIcon icon={faPlus} /> nuova sede
        </Link>
      </div>
      {locations.length > 0 ? (
        <TableX header={sediColumns} data={locations} />
      ) : (
        <p>Non ci sono sedi</p>
      )}
    </>
  );
};

export default LocationsPage;
