
export const getHeaderInfo = () => {
    const xToken = localStorage.getItem("X-Token");
    const email = localStorage.getItem("username");
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-Token': xToken,
        'X-Usr': email
    }
}

export const URL_ARTICLE = 'https://www.monkeyclick.it/gestionale/article.php'
export const URL_CUSTOMERS = 'https://www.monkeyclick.it/gestionale/customers.php'
export const URL_LOCATIONS = 'https://www.monkeyclick.it/gestionale/locations.php'
export const URL_LOGIN = 'https://www.monkeyclick.it/gestionale/login.php'
export const URL_USERS = 'https://www.monkeyclick.it/gestionale/users.php'
export const URL_PUBLISHERS = 'https://www.monkeyclick.it/gestionale/publisher.php'
export const URL_PROVIDERS = 'https://www.monkeyclick.it/gestionale/providers.php' 