import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import FormX from "../components/form/form";
import clientsForm from "../forms/clients.json";
import clientsUi from "../forms/clientsUi.json";
import PurchaseHistory from "../components/history/purchaseHistory";
import CardClient from "../components/cards/cardClient";
import { createClientDataByForm } from "../utils";
import React, { useEffect } from "react";
import { AlertType, ContactsEnum, InfoClient } from "../models";
import { getClientsById, updateClient } from "../api/client";
import Alert from "../components/alert/alert";
import Spinner from "../components/spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import moment from "moment";
import { updateClientsSelectors } from "../redux/slices/clients/clients";

const ClientsEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const selectorClients = useSelector(
    (state: RootState) => state.client.clients
  );
  const [formDataValue, setFormData] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageToShow, setMessage] = React.useState<string | undefined>(
    undefined
  );

  const clientToUpdate = React.useMemo(
    () => selectorClients.find((client) => client.id_cliente === params.id),
    [params.id, selectorClients]
  );

  const [dismiss, setDismiss] = React.useState<boolean>(false);

  const onDismiss = () => {
    setDismiss(!dismiss);
    navigate("/clienti");
  };

  const handleEditClient = async (clientToUpdate: InfoClient) => {
    if (clientToUpdate) {
      const clientDataToUpdate = createClientDataByForm(
        clientToUpdate,
        true
      ) as InfoClient;
      try {
        setLoading(true);
        const res = await updateClient(clientDataToUpdate);
        if (res.data && res.data.nominativo && res.status === 200) {
          setMessage("success");
          dispatch(updateClientsSelectors(res.data));
        } else {
          setMessage(`Cliente non modificato`);
        }
        setLoading(false);
      } catch (err: any) {
        console.log("err", err);
        setMessage(err.message);
        setLoading(false);
      }
    }
  };

  const getDefaultFormData = React.useCallback(
    (client?: InfoClient) => {
      const clientToHandle = client ? client : clientToUpdate;
      if (clientToHandle) {
        const contacts: { contactType: ContactsEnum; contact: string }[] = [];
        if (clientToHandle.cellulare) {
          contacts.push({
            contactType: ContactsEnum.Phone,
            contact: clientToHandle.cellulare,
          });
        }
        if (clientToHandle.email) {
          contacts.push({
            contactType: ContactsEnum.Email,
            contact: clientToHandle.email,
          });
        }
        if (clientToHandle.fax) {
          contacts.push({
            contactType: ContactsEnum.Fax,
            contact: clientToHandle.fax,
          });
        }
        if (clientToHandle.sito_web) {
          contacts.push({
            contactType: ContactsEnum.WebSite,
            contact: clientToHandle.sito_web,
          });
        }
        return {
          code: clientToHandle.id_cliente,
          nominative: clientToHandle.nominativo,
          dateOfBirth: clientToHandle.birthday
            ? moment(clientToHandle.birthday).format("YYYY-MM-DD")
            : undefined,
          address: clientToHandle.indirizzo,
          cap: clientToHandle.cap ? parseInt(clientToHandle.cap) : undefined,
          contacts: contacts,
          color: clientToHandle.colore_semaforo,
          city: clientToHandle.comune ?? undefined,
          note: clientToHandle.note ?? undefined,
        };
      }
    },
    [clientToUpdate]
  );

  const init = React.useCallback(async () => {
    if (clientToUpdate) {
      const defaultForm = getDefaultFormData();
      if (defaultForm) {
        setFormData(defaultForm);
      }
    } else {
      if (params.id) {
        setLoading(true);
        const res = await getClientsById(params.id);
        if (res.data.length > 0) {
          const defaultForm = getDefaultFormData(res.data[0]);
          setFormData(defaultForm);
          setLoading(false);
        }
      }
    }
  }, [clientToUpdate, getDefaultFormData, params.id]);

  useEffect(() => {
    init();
  }, [selectorClients]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="headerSection">
        <h3>{params.id ? "Modifica cliente" : "Inserisci nuovo cliente"}</h3>
        <Link to="/clienti" className="btn btn-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> indietro
        </Link>
      </div>
      <FormX
        form={clientsForm}
        ui={clientsUi}
        submitLabel={params.id ? "Aggiorna" : "Salva"}
        onAction={handleEditClient}
        formData={formDataValue}
      />
      {params.id ? (
        <>
          <PurchaseHistory />
          <CardClient clientId={params.id} />
        </>
      ) : null}
      {messageToShow ? (
        messageToShow !== "success" ? (
          <Alert
            type={AlertType.danger}
            message={messageToShow}
            onDismiss={onDismiss}
          />
        ) : (
          <Alert
            type={AlertType.success}
            message={"Client updated"}
            onDismiss={onDismiss}
          />
        )
      ) : null}
    </>
  );
};

export default ClientsEditPage;
