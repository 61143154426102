import styled from 'styled-components';

export const SpinnerStyle = styled.div`
    background: var(--green);
    height: 100vh;
	padding: 150px calc(50% - 50px);
    & > div {
        width: 100px;
        height: 100px;
        border: 6px solid #fff;
        border-right-color: transparent;
    }
`;
