import moment from 'moment'

export interface InfoClientBase {
    nominativo: string,
    indirizzo: string,
    colore_semaforo: string, //colore semaforo di default all'add di un cliente è verde
    cellulare?: string,
    cap?: string,
    comune?: string,
    fax?: string,
    email?: string,
    sito_web?: string,
    note?: string,
    type?: string,
    birthday?: moment.Moment,
    creationDate?: moment.Moment,
    soundex?: string //TODO: cos è?
}

export interface InfoClient extends InfoClientBase {
    id_cliente: string //codice cliente (codice tessera) non te lo passo in post perchè lo aggiungiamo autoincrement sul db
}

export enum ContactsEnum {
    Phone = "Telefono",
    Fax = "Fax",
    Email = "e-mail",
    WebSite = "Sito Web"
}