import { Route, Routes } from "react-router-dom";
import LoginPage from "../views/login";
import HomePage from "../views/home";
import NoContent from "../views/noContent";
import LocationsPage from "../views/locations";
import LocationsEditPage from "../views/locationsEdit";
import UsersPage from "../views/users";
import UsersEditPage from "../views/usersEdit";
import ClientsPage from "../views/clients";
import ClientsAddPage from "../views/clientsAdd";
import ClientsEditPage from "../views/clientsEdit";
import ArticlesPage from "../views/articles";
import ArticlesEditPage from "../views/articlesEdit";
import LocationsDeletePage from "../views/locationsDeletePage";
import UsersDeletePage from "../views/usersDeletePage";
import PublishersPage from "../views/publishers";
import PublisherEditPage from "../views/publishersEdit";
import PublishersDelete from "../views/publishersDeletePage";
import ProvidersPage from "../views/providers";
import ProviderEditPage from "../views/providersEdit";
import ProvidersDelete from "../views/providersDeletePage";

export const AppRoutes = () => {
  //TODO: usare react.lazy
  return (
    <Routes>
      <Route path="/" element={<LoginPage />}></Route>
      <Route path="/dashboard" element={<HomePage />}></Route>
      <Route path="/sedi" element={<LocationsPage />}></Route>
      <Route path="/sedi/nuova" element={<LocationsEditPage />}></Route>
      <Route path="/sedi/modifica/:id" element={<LocationsEditPage />}></Route>
      <Route
        path="/sedi/delete/:id/:name"
        element={<LocationsDeletePage />}
      ></Route>
      <Route path="/utenti" element={<UsersPage />}></Route>
      <Route path="/utenti/nuovo" element={<UsersEditPage />}></Route>
      <Route path="/utenti/modifica/:id" element={<UsersEditPage />}></Route>
      <Route
        path="/utenti/delete/:id/:name"
        element={<UsersDeletePage />}
      ></Route>
      <Route path="/clienti" element={<ClientsPage />}></Route>
      <Route path="/clienti/nuovo" element={<ClientsAddPage />}></Route>
      <Route path="/clienti/modifica/:id" element={<ClientsEditPage />}></Route>
      <Route path="/articoli/" element={<ArticlesPage />}></Route>
      <Route path="/articoli/nuova" element={<ArticlesEditPage />}></Route>
      <Route path="/editori" element={<PublishersPage />}></Route>
      <Route path="/editori/nuovo" element={<PublisherEditPage />}></Route>
      <Route
        path="/editori/modifica/:id"
        element={<PublisherEditPage />}
      ></Route>
      <Route
        path="/editori/delete/:id/:name"
        element={<PublishersDelete />}
      ></Route>
      <Route path="/fornitori" element={<ProvidersPage />}></Route>
      <Route path="/fornitori/nuovo" element={<ProviderEditPage />}></Route>
      <Route
        path="/fornitori/modifica/:id"
        element={<ProviderEditPage />}
      ></Route>
      <Route
        path="/fornitori/delete/:id/:name"
        element={<ProvidersDelete />}
      ></Route>
      <Route path="/notfound" element={<NoContent />}></Route>
      <Route path="*" element={<NoContent />} />
    </Routes>
  );
};
