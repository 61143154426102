import { Link, useNavigate } from "react-router-dom";
import { SideMenuStyle } from "./style";
import logo from "../../assets/images/logoPanel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faShop,
  faUserTie,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { capitalizeFirstLetter } from "../../utils";
import React from "react";

const SideMenu = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [show, setShow] = React.useState<string>("");

  const toggleMenu = React.useCallback(
    (voice: string) => {
      if (voice === show) {
        setShow("");
      } else {
        setShow(voice);
      }
    },
    [show]
  );

  return (
    <SideMenuStyle className="flex-shrink-0 p-3">
      <center>
        <img
          src={logo}
          alt="logo panel"
          onClick={() => navigate("/dashboard")}
        />
      </center>
      <span className="name">
        Ciao <b>{capitalizeFirstLetter(user.name)}</b>
      </span>
      <ul className="list-unstyled ps-0">
        <li className={"mb-1 " + (show === "shop" ? "open" : "close")}>
          <button
            className="btn btn-toggle collapsed btn-menu"
            onClick={() => toggleMenu("shop")}
          >
            Negozio <FontAwesomeIcon icon={faArrowDown} />
          </button>
          <div className="collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <Link to="/sedi">
                  <FontAwesomeIcon icon={faShop} />
                  Sedi
                </Link>
              </li>
              <li>
                <Link to="/utenti">
                  <FontAwesomeIcon icon={faUserTie} />
                  Utenti
                </Link>
              </li>
              <li>
                <Link to="/clienti">
                  <FontAwesomeIcon icon={faUser} />
                  Clienti
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className={"mb-1 " + (show === "product" ? "open" : "close")}>
          <button
            className="btn btn-toggle collapsed btn-menu"
            onClick={() => toggleMenu("product")}
          >
            Articoli <FontAwesomeIcon icon={faArrowDown} />
          </button>
          <div className="collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <Link to="editori">
                  <FontAwesomeIcon icon={faArrowRight} />
                  Editori
                </Link>
              </li>
              <li>
                <Link to="fornitori">
                  <FontAwesomeIcon icon={faArrowRight} />
                  Fornitori
                </Link>
              </li>
              <li>
                <Link to="articoli">
                  <FontAwesomeIcon icon={faArrowRight} />
                  Articoli
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </SideMenuStyle>
  );
};

export default SideMenu;
