import { useNavigate } from "react-router-dom";

interface ModalProps {
  title: string;
  message: string;
  onDelete: () => void;
  urlBack: string;
}

const Modal = (props: ModalProps) => {
  const navigate = useNavigate();
  return (
    <div className="modal" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => navigate(props.urlBack)}
            ></button>
          </div>
          <div className="modal-body">
            <p>{props.message}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => navigate(props.urlBack)}
            >
              Chiudi
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={props.onDelete}
            >
              Conferma
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
