import React from "react";
import { Layout } from "../components/layout/style";

const NoContent = () => {
  return (
    <Layout>
      <center>
        <h1>Page Not Found</h1>
      </center>
    </Layout>
  );
};

export default NoContent;
