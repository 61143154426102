import { Layout } from "../components/layout/style";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import NoContent from "./noContent";
import PurchaseHistory from "../components/history/purchaseHistory";
import CardClient from "../components/cards/cardClient";

const HomePage = () => {
  const user = useSelector((state: RootState) => state.user); //TODO: usarlo?
  const usernameFromLocalStorate = localStorage.getItem("username");

  return !usernameFromLocalStorate ? (
    <NoContent />
  ) : (
    <Layout>
      <h3>Dashboard</h3>
      <PurchaseHistory />
      <CardClient />
    </Layout>
  );
};

export default HomePage;
