import DataTable from "react-data-table-component";
import TableStyle from "./style";
import { TableXType } from "./types";

const TableX = (props: TableXType) => {
  return (
    <TableStyle>
      <DataTable columns={props.header} data={props.data} />
    </TableStyle>
  );
};

export default TableX;
