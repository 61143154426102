import axios from "axios"
import { InfoClient, InfoClientBase, ReturnCallbackType } from "../../models"
import { getHeaderInfo, URL_CUSTOMERS } from "../utils"


export const addClient = async (data: InfoClientBase): Promise<ReturnCallbackType<InfoClient | null>> => { //TODO: verrà sostituita con una get di tutti i clienti che devono ritirare oggi
    const result: ReturnCallbackType<InfoClient | null> = await axios.post(URL_CUSTOMERS, data,
        {
            headers: getHeaderInfo(),
        }

    )
    return result
}

export const updateClient = async (data: InfoClient): Promise<ReturnCallbackType<InfoClient | null>> => {
    const result: ReturnCallbackType<InfoClient | null> = await axios.put(URL_CUSTOMERS, data, {
        headers: getHeaderInfo(),
    })
    return result
}

export const getAllClients = async (): Promise<ReturnCallbackType<InfoClient[]>> => {
    const result: ReturnCallbackType<InfoClient[]> = await axios.get(URL_CUSTOMERS, {
        headers: getHeaderInfo()
    })
    return result
}

export const getClientsByName = async (nominative: string): Promise<ReturnCallbackType<InfoClient[]>> => {
    const result: ReturnCallbackType<InfoClient[]> = await axios.get(URL_CUSTOMERS, {
        headers: getHeaderInfo(),
        params: {
            nominativo: nominative
        }
    })
    return result
}

export const getClientsById = async (id: string): Promise<ReturnCallbackType<InfoClient[]>> => {
    const result: ReturnCallbackType<InfoClient[]> = await axios.get(URL_CUSTOMERS, {
        headers: getHeaderInfo(),
        params: {
            id_cliente: id
        }
    })
    return result
}

export const deleteClient = async (code: string): Promise<ReturnCallbackType<boolean>> => {
    let urlWithPathParameter = URL_CUSTOMERS + '/' + code
    urlWithPathParameter = urlWithPathParameter.replace(/\s/g, '');
    const result: ReturnCallbackType<boolean> = await axios.delete(urlWithPathParameter, {
        headers: getHeaderInfo(),
    })
    return result
}