import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteUser } from "../api/users";
import Modal from "../components/modal/modal";
import Spinner from "../components/spinner/spinner";

const UserDelete = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const onDelete = async () => {
    if (params.id) {
      setLoading(true);
      await deleteUser(params.id);
      setLoading(false);
      navigate("/utenti");
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <Modal
      title="Cancella UTENTE"
      message={`Sei sicuro di cancellare quest'utente con nome ${params.name} e con id ${params.id}?`}
      onDelete={onDelete}
      urlBack="/utenti"
    />
  );
};

export default UserDelete;
