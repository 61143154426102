import Login from '../components/login/login';
import { Layout } from '../components/layout/style';

const LoginPage = () => {
    return (
        <Layout>
            <Login />
        </Layout>
    );
};

export default LoginPage;
